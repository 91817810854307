/*=========================================
	++ common.js ++ Ver.1.6.1
	Last update 2020.9.10
==========================================*/
/*
 * 2020  9/10 全体を一新
 * 2019  5/ 7  *object-fit-images 追加
 * 2018 11/20  *ブラウザ判定処理を一新
 * 2018 10/25  *jQuery 3系 に対応
 */

'usestrict';

//=====================================
//	Detecting Browser & Device Ver.1.4.2
//============================================

//++++++++++++ モバイル/タブレット/ブラウザ判定処理 ++++++++++++

//ブラウザ/デバイス 種別
var isMobile = false,
	isTablet = false,
	isRetina = false,
	isMobileTablet = false,
	isAndroid = false;

//モバイル　タブレット　判定
//https://w3g.jp/blog/js_browser_sniffing2015
var _ua = (function (u) {
	return {
		Tablet: (u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1) ||
			u.indexOf("ipad") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
			u.indexOf("kindle") != -1 ||
			u.indexOf("silk") != -1 ||
			u.indexOf("playbook") != -1,
		Mobile: (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
			u.indexOf("iphone") != -1 ||
			u.indexOf("ipod") != -1 ||
			(u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
			(u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) |
			u.indexOf("blackberry") != -1
	};
})(window.navigator.userAgent.toLowerCase());

//モバイル
if (_ua.Mobile) {
	isMobile = true;
	isMobileTablet = true;
}
//タブレット
if (_ua.Tablet) {
	isTablet = true;
	isMobileTablet = true;
}

//Retina Display 判定
if (window.retina || window.devicePixelRatio > 1) {
	isRetina = true;
}

//Android 判定
if (/android[\/\s](\d+\.\d+)/.test(navigator.userAgent)) {
	isAndroid = true;
}


//============================================
//	デバイス　ブラウザ別の処理
//============================================

//++++++++++++ モバイル/タブレット クラス追加 ++++++++++++

$(function () {
	if (isMobile) {
		//HTMLにclass isMobileを追加
		$('html').addClass('isMobile');
		$('html').addClass('isMobileTablet');
	} else if (isTablet) {
		//HTMLにclass isTabletを追加
		$('html').addClass('isTablet');
		$('html').addClass('isMobileTablet');
	}
	//Retina Display isRetina クラス追加
	if (isRetina) {
		$('html').addClass('isRetina');
	}
});




//============================================
//	汎用スクリプト 
//============================================


//++++++ Copyright ++++++
// Copyright <span class="copyYear">&copy;</span>

$(function () {
	let date = new Date();
	let copyYear = date.getFullYear();
	copyYear = '&copy; ' + copyYear;
	$('.copyYear').html(copyYear);
});


$(function () {
	$(".qa_list dd").hide();
	$(".qa_list dl").on("click", function (e) {
		$('dd', this).slideToggle('fast');
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
		}
	});
});



$(function () {
	$(".menubtn").click(function () {
		$(this).toggleClass('active');
		$('body').toggleClass('no-scroll');
		$("#spnav").toggleClass('panelactive');
	});

	$("#spnav a").click(function () {
		$(".menubtn").removeClass('active');
		$('body').removeClass('no-scroll');
		$("#spnav").removeClass('panelactive');
	});

	$(".cancel").click(function () {
		$('body').removeClass('no-scroll');
		$("#spnav").removeClass('panelactive');
	});
})




$(function () {
	$(".snav_cat a").click(function () {
		$(this).toggleClass('active');
		$('.snav_sub').toggleClass('show');
	});
})



//pagetop
$(function () {
	var topBtn = $('.footerfix');
	topBtn.hide();
	//スクロールが100に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			//ボタンの表示方法
			topBtn.fadeIn();
		} else {
			//ボタンの非表示方法
			topBtn.fadeOut();
		}
	});
	//スクロールしてトップ
	topBtn.click(function () {
		$('body,  html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});
});

const headerH = $("#header").outerHeight(true); //headerの高さを取得    

//スクロールすると上部に固定させるための設定を関数でまとめる
function FixedAnime() {
	var headerH = $('#header').outerHeight(true);
	var scroll = $(window).scrollTop();
	if (scroll >= headerH) { //headerの高さ以上になったら
		$('#header').addClass('fixed'); //fixedというクラス名を付与
	} else { //それ以外は
		$('#header').removeClass('fixed'); //fixedというクラス名を除去
	}
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	FixedAnime(); /* スクロール途中からヘッダーを出現させる関数を呼ぶ*/
});






// 動きのきっかけの起点となるアニメーションの名前を定義
function fadeAnime() {
	// ふわっ
	$('.fadeUpTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeUp'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述



function fadeLeftAnime() {
	$('.fadeLeftTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeLeft'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeLeftAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述


function fadeRightAnime() {
	$('.fadeRightTrigger').each(function () { //fadeUpTriggerというクラス名が
		var elemPos = $(this).offset().top - 50; //要素より、50px上の
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight) {
			$(this).addClass('fadeRight'); // 画面内に入ったらfadeUpというクラス名を追記
		}
	});
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	fadeRightAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述




function delayScrollAnime() {
	var time = 0.1; //遅延時間を増やす秒数の値
	var value = time;
	$('.delayScroll').each(function () {
		var parent = this; //親要素を取得
		var elemPos = $(this).offset().top; //要素の位置まで来たら
		var scroll = $(window).scrollTop(); //スクロール値を取得
		var windowHeight = $(window).height(); //画面の高さを取得
		var childs = $(this).children(); //子要素を取得

		if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) { //指定領域内にスクロールが入ったらまた親要素にクラスplayがなければ
			$(childs).each(function () {

				if (!$(this).hasClass("fadeUp")) { //アニメーションのクラス名が指定されているかどうかをチェック

					$(parent).addClass("play"); //親要素にクラス名playを追加
					$(this).css("animation-delay", value + "s"); //アニメーション遅延のCSS animation-delayを追加し
					$(this).addClass("fadeUp"); //アニメーションのクラス名を追加
					value = value + time; //delay時間を増加させる

					//全ての処理を終わったらplayを外す
					var index = $(childs).index(this);
					if ((childs.length - 1) == index) {
						$(parent).removeClass("play");
					}
				}
			})
		} else {
			$(childs).removeClass("fadeUp"); //アニメーションのクラス名を削除
			value = time; //delay初期値の数値に戻す
		}
	})
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
	delayScrollAnime(); /* アニメーション用の関数を呼ぶ*/
}); // ここまで画面をスクロールをしたら動かしたい場合の記述









//============================================
//	グローバルナビ　現在地
//============================================


new ScrollHint('.js-scrollable', {
	suggestiveShadow: true,
	i18n: {
		scrollable: 'スクロールできます'
	}
});


$(function () {
	$('.venobox').venobox({
		infinigall: true,
		numeratio: true,
	});
});



$(function () {
	// 初期状態で「ALL」のリンクに hilight クラスを追加し、すべての投稿を表示
	$('.filter-link-all').addClass('hilight');
	$('.post-item').show().addClass('show');

	$('.filter-link-all').click(function (e) {
		e.preventDefault();
		// すべての投稿を表示
		$('.post-item').fadeIn().addClass('show');

		// 「ALL」のリンクに hilight クラスを追加し、他のリンクからは削除
		$('#filter-nav .filter-link').removeClass('hilight');
		$(this).addClass('hilight');
	});

	$('.filter-link').click(function (e) {
		e.preventDefault();

		var selectedTerm = $(this).data('term');

		// すべての投稿を一度非表示にして、該当する投稿のみを表示
		$('.post-item').hide().removeClass('show').filter(function () {
			return $(this).data('term').includes(selectedTerm);
		}).fadeIn().addClass('show');

		// 選択されたタクソノミーのリンクに hilight クラスを追加
		$('#filter-nav .filter-link').removeClass('hilight');
		$(this).addClass('hilight');

		// 「ALL」のリンクから hilight クラスを削除
		$('.filter-link-all').removeClass('hilight');
	});
});







document.addEventListener("DOMContentLoaded", () => {
	setUpAccordion();
});


const setUpAccordion = () => {
	const details = document.querySelectorAll(".js-details");
	const RUNNING_VALUE = "running"; // アニメーション実行中のときに付与する予定のカスタムデータ属性の値
	const IS_OPENED_CLASS = "is-opened"; // アイコン操作用のクラス名

	details.forEach((element) => {
		const summary = element.querySelector(".js-summary");
		const content = element.querySelector(".js-content");

		summary.addEventListener("click", (event) => {
			// デフォルトの挙動を無効化
			event.preventDefault();

			// 連打防止用。アニメーション中だったらクリックイベントを受け付けないでリターンする
			if (element.dataset.animStatus === RUNNING_VALUE) {
				return;
			}

			// detailsのopen属性を判定
			if (element.open) {
				// アコーディオンを閉じるときの処理
				// アイコン操作用クラスを切り替える(クラスを取り除く)
				element.classList.toggle(IS_OPENED_CLASS);

				// アニメーションを実行
				const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
				// アニメーション実行中用の値を付与
				element.dataset.animStatus = RUNNING_VALUE;

				// アニメーションの完了後に
				closingAnim.onfinish = () => {
					// open属性を取り除く
					element.removeAttribute("open");
					// アニメーション実行中用の値を取り除く
					element.dataset.animStatus = "";
				};
			} else {
				// アコーディオンを開くときの処理
				// open属性を付与
				element.setAttribute("open", "true");

				// アイコン操作用クラスを切り替える(クラスを付与)
				element.classList.toggle(IS_OPENED_CLASS);

				// アニメーションを実行
				const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
				// アニメーション実行中用の値を入れる
				element.dataset.animStatus = RUNNING_VALUE;

				// アニメーション完了後にアニメーション実行中用の値を取り除く
				openingAnim.onfinish = () => {
					element.dataset.animStatus = "";
				};
			}
		});
	});
}

/**
 * アニメーションの時間とイージング
 */
const animTiming = {
	duration: 400,
	easing: "ease-out"
};

/**
 * アコーディオンを閉じるときのキーフレーム
 */
const closingAnimKeyframes = (content) => [{
	height: content.offsetHeight + 'px', // height: "auto"だとうまく計算されないため要素の高さを指定する
	opacity: 1,
}, {
	height: 0,
	opacity: 0,
}];

/**
 * アコーディオンを開くときのキーフレーム
 */
const openingAnimKeyframes = (content) => [{
	height: 0,
	opacity: 0,
}, {
	height: content.offsetHeight + 'px',
	opacity: 1,
}];